
import { defineComponent, onMounted, ref, reactive } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useToast } from "vue-toastification";
import ApiService from "@/core/services/ApiService";
import { useRouter, useRoute } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Multiselect from "@vueform/multiselect";

export default defineComponent({
  name: "Detail Sekolah",
  components: {
    ErrorMessage,
    Field,
    Form,
    Loading,
    Multiselect,
  },

  data() {
    return {
      isLoading: false,
      fullPage: true,
      isColor: "#007BFF",
      isBackgroundColor: "#A3A3A3",
      isOpacity: 0.5,

      listSekolah: [],

      url: "",
      errorsMessage: {
        errorjumlah: "",
        errorproductSekolah: "",
      },
      hideDescription: false,

      target: {
        idSekolah: localStorage.getItem("user_account")
          ? JSON.parse(localStorage.getItem("user_account") as string).schoolId
          : "",
        jumlah: "",
        catatan: "",
      },

      detail: {
        name: "",
        createTime: "2022-02-15T13:33:33",
        creatorId: "",
        deleteBy: "",
        deleteTime: "",
        description: "",
        discount: 0,
        editorId: "",
        imageshow: "",
        imgUrl: ["null", "null", "null", "null", "null"],
        isDelete: false,
        price: 0,
        productCatagory: "",
        stock: 0,
        updateTime: "",
        _id: "",
      },
    };
  },

  beforeMount() {
    this.getIdSekolah();
    this.getListSekolah();
    this.heightCalculation();
  },

  methods: {
    heightCalculation() {
      let element = document.getElementsByClassName("item-description");
      console.log(element, element.item(0));
      for (let index = 0; index < element.length; index++) {
        console.log(element[index]);
      }
    },
    showHide() {
      this.hideDescription = !this.hideDescription;
    },
    changeImage(item) {
      console.log(item);
      this.detail.imageshow = item;
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    getIdSekolah() {
      store.dispatch(Actions.VERIFY_AUTH);

      this.isLoading = true;

      const toast = useToast();
      const $route = useRoute();

      ApiService.getWithoutSlug(
        "crmv2/order/cart/admin/" +
          this.$route.params._id +
          "/" +
          this.$route.params.cartId
      )
        .then((res) => {
          // this.defaultItem = response.data.data.result;
          this.detail = res.data;
          this.target.jumlah = res.data.quantity;
          this.target.idSekolah = res.data.schoolId;
          this.detail.imgUrl = Object.values(res.data.productData.imageProduct);
          Object.values(res.data.productData.imageProduct).map(
            (item, index) => {
              setTimeout(() => {
                ApiService.getWithoutSlug(
                  "crmv2/main_image/image/get/base64/" + item
                )
                  .then((resImg) => {
                    this.detail.imgUrl[
                      index
                    ] = `data:image/jpg;base64,${resImg.data}`;

                    if (item) {
                      this.detail.imageshow = `data:image/jpg;base64,${resImg.data}`;
                    }
                    // this.detail.imageshow = `data:image/jpg;base64,${resImg.data}`;
                  })
                  .catch((err) => {
                    toast.error(err?.res?.data?.detail);
                    // this.isLoading = false;
                  });
              }, 500);
            }
          );

          this.isLoading = false;
        })
        .catch((e) => {
          toast.error(e.res.data.detail);

          this.isLoading = false;
        });
    },
    updateCart() {
      store.dispatch(Actions.VERIFY_AUTH);

      this.isLoading = true;

      const toast = useToast();
      const $route = useRoute();

      let detail = {};
      ApiService.putWithData(
        "crmv2/order/cart/admin/" +
          this.$route.params._id +
          "/" +
          this.$route.params.cartId,
        detail
      )
        .then((res) => {
          this.isLoading = false;
        })
        .catch((e) => {
          toast.error(e.res.data.detail);

          this.isLoading = false;
        });
    },
    getListSekolah() {
      this.isLoading = true;
      ApiService.getWithoutSlug("crmv2/main_school/school/school").then(
        ({ data }) => {
          this.listSekolah = data;
          localStorage.setItem("listSekolah", JSON.stringify(data));
        }
      );
      this.isLoading = false;
    },
    getImage(imageName) {
      const toast = useToast();
      let returnImage = "";
      ApiService.getWithoutSlug(
        "crmv2/main_image/image/get/base64/" + imageName
      )
        .then((resImg) => {
          // console.log(resImg.data);
          return `data:image/jpg;base64,${resImg.data}`;
          returnImage = `data:image/jpg;base64,${resImg.data}`;
        })
        .catch((err) => {
          toast.error(err?.res?.data?.detail);
        });
      // return returnImage;
    },
  },
});
